.portfolio_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio_item{
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid var(--color-bg-variant);
    transition: var(--transition);
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;

}

.portfolio_item img {
    border: 1px solid transparent;
    position: absolute;
    /*aspect-ratio: 1 / 1;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.02;
    vertical-align: top;
    horiz-align: left;
    transform: scale(1.2);
    transition: var(--transition);
}

.portfolio_item img:hover {
    position: absolute;
    /*aspect-ratio: 1 / 1;*/
    left: 0;
    top: 0;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;
    transform: scale(1);
    transition: var(--transition);
}

.portfolio_item_header{
    display: flex;
    flex-direction: column;
    flex:1;
    gap: 1.5rem;
}
.details{
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 1rem;

}

.details_header{
    text-align: center;
}
.details_row{
    display: flex;
    flex:1;
    flex-direction: row;
    width: auto;
    gap: 1rem;
}

.w100{
    width: 100%;
}


.portfolio_item:hover{
    border-color: var(--color-primary-variant);
    transition: var(--transition);
}

.portfolio_item-image{
    overflow: hidden;
    border-radius: 1.5rem 0 0 0 ;
}

.float{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    margin-right: 3rem;
    float: left;
}
.portfolio_item-image > img{
    height: 15em;
    width: auto;
}

.portfolio_item h3{
    margin: 0rem 0 0.0rem;
}

.portfolio_item small{
    margin: 0.5rem 0 0.5rem;
}

.portfolio_item-cta {
    margin-right: 1rem;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    justify-content: flex-end;
}


@media screen and (max-width:1024px){
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


@media screen and (max-width:600px){
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }

}

hr {
    width: 70%;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    background-color: #b7d0e2;
}


li{
    display: inline;
    margin-right: 10px;
}

.technologies {
    display: flex;
    justify-content: center;
    flex-wrap: inherit;
    text-align: center;
}