.menu_bar{

    padding: 0.5rem;
    width: max-content;
    z-index: 2;
    position: fixed;
    right: 2rem;
    transform: translateX(-50%) ;
    top: 2rem;
    display: flex;
    justify-content: space-between;
    border-radius: 20%;
    backdrop-filter: blur(15px);

}

.menu_bar:hover{
    color: rgba(255, 255, 255, 0.5);;
}

.menu_bar div {
    background: transparent;
    padding: 0.2rem;
    display: flex;
    color: rgba(241, 232, 230, 0.19);
    font-size: 2rem;
}

.menu_bar div:hover{
    color: var(--color-light);
    cursor: pointer;
}

.menu_bar div.active {
    background-color: transparent;
    color: var(--color-white);
    border-bottom-color: var(--color-light);
}