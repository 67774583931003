header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header_container{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: space-between;
}

.header_sub_container{
    text-align: start;
    height: 75%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content:flex-start;
    max-height: 4rem;
    /*flex-wrap: wrap;*/

}

.cta_container{
    display: flex;
    justify-content: center;
}

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: -3rem;
    top: 5%;
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 30rem;
    height: 30rem;
    position: absolute;
    /* left: calc(50% - 11rem ); half width = 11rem*/
    /*left: calc(50% - 11rem );*/
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 12rem 12rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}


.me > img {
    position: absolute;
    bottom: 0;
    right: 1%;
}

.slogan{
    margin: 5rem;
    text-align: center;
    width: 100rem;
}


@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }

    .cta {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

}

@media screen and (max-width:600px){
    header{
        height: 100vh;

    }
    .header_socials,
    .scroll_down{
        display: none;
    }
    .header_container{
        flex-direction: column;
    }

}


.name_animation{
    color: #ffffff;
    font-size: 50px;
    font-weight: bold;
    font-family: monospace;
    letter-spacing: 7px;
}
.name_animation span{
    transition: .5s linear
}
.name_animation:hover span:nth-child(1){
    margin-right: 5px
}
.name_animation:hover span:nth-child(1):after{
    content: "'";
}
.name_animation:hover span:nth-child(2){
    margin-left: 30px
}
.name_animation:hover span{
    color: #fff;
    text-shadow: 0 0 10px #fff,
    0 0 20px #fff,
    0 0 40px #fff;
}


.type_writer_container{
    display: inline-block;
}
.typed-out{
    overflow: hidden;
    border-right: .1em solid var(--color-light);
    border-spacing: 1em;
    white-space: nowrap;
    animation:
            typing 3s forwards,
            blink-caret 1.2s step-end infinite;
    font-size: 1.6rem;
    width: 0;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--color-light); }
}