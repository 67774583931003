nav{
    background: rgba(0, 0, 0, 0.6);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 999;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8%;
    display: flex;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover{
    background: rgba(0, 0, 0, 0.3)
}

nav a.active {
    background-color: var(--color-bg);
    color: var(--color-white);
    border: 0.2rem solid;
    border-bottom-color: var(--color-light);
    transition: all 100ms ease;
}