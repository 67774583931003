.container.testimonials_container {
  width: 40%;
  height: auto;
  padding-bottom: 3rem;
}

.swiper-pagination-bullet ,
.swiper-pagination-bullet-active{
    background: var(--color-primary) !important;
}

.client_avatar {
  width: 6rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);
}
.client_avatar img{
  scale: 130%;
  bottom: -5;
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  
}
.client_name{
  margin: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

.client_review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

/** TABLES **/
@media screen and (max-width: 1024px) {
  .container.testimonials_container {
    width: 60%;
  }
}

/** Mobiles **/
@media screen and (max-width: 600px) {
  .container.testimonials_container {
    width: var(--container-widht-sm);
  }
}
