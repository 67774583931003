@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import "assets/styles/toast.css";
@import "assets/styles/tooltip.css";

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/**
:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --container-widht-lg: 75%;
    --container-widht-md: 86%;
    --container-widht-sm: 90%;

    --transition: all 400ms ease;

}
*/





:root {

    /* Looks good */
/*--color-bg: #361d32;*/
/*    --color-bg-variant: #543c52;*/
/*    --color-primary: #f55951;*/
/*    --color-primary-variant: #f55951;*/
/*    --color-white: #F6F2FF;*/
/*    --color-light: #f1e8e6;*/



    /*--color-bg: #12492f;*/
    /*--color-bg-variant: #0a2f35;*/
    /*--color-primary: #f7a325;*/
    /*--color-primary-variant: #f56038;*/
    /*--color-white: #FFF;*/
    /*--color-light:#ffca7a;*/


    /**
    NICE!
     */
    --color-bg: #1e181d;
    --color-bg-variant: #372835;
    --color-primary: #53b5a3;
    --color-primary-variant: #b9be12;
    --color-white: #F6F2FF;
    --color-light: #f1e8e6;

    /*!***/
    /*!*Celos Farben*!*/
    /*!* *!*!*/
    /*--color-bg: #1e181d;*/
    /*--color-bg-variant: #372835;*/
    /*--color-primary: #C2E7DA;*/
    /*--color-primary-variant: #b9be12;*/
    /*--color-white: #F6F2FF;*/
    /*--color-light: #DAD6D6;*/

/*----------------------Favorite-------------------------- */
    /*    !***/
    /*Celos Farben*/
    /* *!*/
    /*--color-bg: #1a1c1b;*/
    /*--color-bg-variant: #3f4a47;*/
    /*--color-bg-variant-three: #313937;*/
    /*--color-bg-variant-two: #232927;*/
    /*--color-primary: #a4d0bf;*/
    /*--color-primary-variant: #cecece;*/
    /*--color-white: #d8d8d8;*/
    /*--color-light: #bcbcbc;*/

    /*----------------------Favorite-------------------------- */
    /*--color-bg: #361d32;*/
    /*--color-bg-variant: #543c52;*/
    /*--color-primary: #f55951;*/
    /*--color-primary-variant: #f55951;*/
    /*--color-white: #F6F2FF;*/
    /*--color-light: #f1e8e6;*/



    /*--color-bg: #361d32;*/
    /*--color-bg-variant: #543c52;*/
    /*--color-bg-variant-two: #4c344a;*/
    /*--color-bg-variant-three: #949494;*/
    /*--color-primary: #f55951;*/
    /*--color-primary-variant: #f55951;*/
    /*--color-white: #F6F2FF;*/
    /*--color-light: rgb(241, 232, 230);*/

    --container-widht-lg: 75%;
    --container-widht-md: 86%;
    --container-widht-sm: 90%;

    --transition: all 400ms ease;


}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--color-bg);
    /*background: linear-gradient(-45deg, var(--color-bg-variant-three), var(--color-bg-variant-two), var(--color-bg-variant), var(--color-bg));*/
    /*background-size: 400% 400%;*/
    /*background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/white_wall_hash.png");*/
    /*animation: gradient 30s ease infinite;*/
    color: var(--color-white);
    line-height: 1.7;

}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

/**
background-image: url()
**/

.container {
    width: var(--container-widht-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);

}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}


.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    z-index: 100;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


.hidden{
    opacity: 0;
    /* filter:blur(5px); */
    transform: translateX(-100%);
    transition:all 600ms;
}

.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

/** QUERRIES **/

@media(prefers-reduced-motion){
    .hidden{
        transition:none;
    }
}

/** TABLES **/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-widht-md);
    }

    section {
        margin-top: 6rem;
    }
}


/** Mobiles **/
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-widht-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
}








